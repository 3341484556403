<template>
  <div>
    <a-modal
      v-model="isShow"
      width="300px"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑密码"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-compact-form"
        :form="form"
        @submit="handleSubmit"
      >
        <a-form-item>
          <a-input
            autocomplete="new-password"
            v-decorator="['password', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入密码' },
                { min: 6, message: '密码需要在6位数以上' },
                { pattern: /^\S.*\S$/, message: '首尾不能为空格' },
                { validator: validateToNextPassword }
              ]
            }]"
            placeholder="请设置6位以上登录密码"
            type="password"
          />
        </a-form-item>

        <a-form-item>
          <a-input
            autocomplete="new-password"
            v-decorator="['confirm_password', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请再次输入密码' },
                { validator: compareToFirstPassword }
              ]
            }]"
            placeholder="请再次输入登录密码"
            type="password"
            @blur="handleConfirmBlur"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateUserSelfPassword } from '@/api/user'
export default {
  name: 'EditUserSelfPassword',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    identification: {
      type: String,
      required: true
    },
    nextVerifyId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit_full_name' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    compareToFirstPassword(rule, value, callback) {
      const form = this.form
      if (value && value !== form.getFieldValue('password')) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入相同密码')
      } else {
        callback()
      }
    },

    validateToNextPassword(rule, value, callback) {
      const form = this.form
      if (value && this.confirmDirty) {
        form.validateFields(['confirm_password'], { force: true })
      }
      callback()
    },

    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          updateUserSelfPassword(this.id, Object.assign({ next_verify_id: this.nextVerifyId }, values)).then((res) => {
            if (res.code === 0) {
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
